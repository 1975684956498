import React, { useEffect, useState } from "react";
import Matches from "../../commons/Matches";
import loading from "../../../images/loading-image.svg";
import { useParams } from "react-router-dom";
import {
  useGetEventMatchesQuery,
  useGetMatchByLeagueIdQuery,
  useGetPlayerMatchesQuery,
  useGetPlayersByLeagueIdQuery,
} from "../../../redux/features/api/apiSlice";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { Listbox } from "@headlessui/react";
import { format, eachDayOfInterval, parseISO } from "date-fns";
import loadingImg from "../../../images/loading-image.svg";
import Challenges from "./Challenges";
import { useSelector } from "react-redux";

const getDatesInRange = (startDate, endDate) => {
  if (!startDate || !endDate) return []; // Return an empty array if either date is missing

  return eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
};

const LeagueMatchesTab = ({
  leagueData,
  league,
  event,
  player,
  admin,
  playerId,
}) => {
  const { leagueId, eventId } = useParams();
  let leagueDrawId = localStorage.getItem("leagueDrawId");
  leagueDrawId = JSON.parse(leagueDrawId);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [matches, setMatches] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const dates = getDatesInRange(leagueData?.startDate, leagueData?.endDate);
  const [matchStatus, setMatchStatus] = useState("all");
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [unscheduled, setUnscheduled] = useState(true);
  const displayedDate = dates[currentDateIndex];
  const [query, setQuery] = useState("");

  const { data: leaguePlayers } = useGetPlayersByLeagueIdQuery(
    league ? leagueId : null,
    { skip: !league }
  );
  const { currentUser } = useSelector((state) => state.user);

  const {
    data: leagueMatches,
    isLoading: leagueLoading,
    isError: leagueError,
    refetch: refetchLeague,
  } = useGetMatchByLeagueIdQuery(
    league
      ? new URLSearchParams({
          id: leagueDrawId,
          page: currentPage,
          pageSize,
          matchStatus,
          date:
            displayedDate && league
              ? format(displayedDate, "yyyy-MM-dd")
              : null,
          unscheduled,
          playerId: currentUser?.player?.id,
        }).toString()
      : null,
    { skip: !league }
  );

  const {
    data: eventMatches,
    isLoading: eventLoading,
    isError: eventError,
    refetch: refetchEvent,
  } = useGetEventMatchesQuery(
    event
      ? new URLSearchParams({
          id: eventId,
          page: currentPage,
          pageSize,
          playerId: selectedPlayer?.id ?? selectedPlayer,
        }).toString()
      : null,
    { skip: !event }
  );

  const {
    data: playerMatches,
    isLoading: playerLoading,
    isError: playerError,
    refetch: refetchPlayer,
  } = useGetPlayerMatchesQuery(
    player
      ? new URLSearchParams({
          id: playerId,
          page: currentPage,
          pageSize,
        }).toString()
      : null,
    { skip: !player || !playerId }
  );

  useEffect(() => {
    if (league && leagueMatches?.data) {
      setMatches(leagueMatches.data);
      setTotalPages(leagueMatches.totalPages);
      setAllPlayers(leaguePlayers?.data);
    } else if (event && eventMatches?.data) {
      setMatches(eventMatches.data);
      setTotalPages(eventMatches.totalPages);
      setAllPlayers(leaguePlayers?.data);
    } else if (player && playerMatches?.data) {
      setMatches(playerMatches.data);
      setTotalPages(playerMatches.totalPages);
    }
  }, [
    leagueMatches,
    eventMatches,
    playerMatches,
    league,
    event,
    player,
    selectedPlayer,
    matchStatus,
  ]);

  useEffect(() => {
    if (league) {
      refetchLeague();
    } else if (event) {
      refetchEvent();
    } else if (player && playerId) {
      refetchPlayer();
    }
  }, [
    player,
    league,
    event,
    selectedPlayer,
    currentDateIndex,
    matchStatus,
    unscheduled,
  ]);

  const filteredMatches = selectedPlayer
    ? matches.filter((match) =>
        match.matchPlayers.some((p) => p.id === selectedPlayer.id)
      )
    : matches;

  // Filter players based on input query
  const filteredPlayers = allPlayers?.filter((player) =>
    `${player.firstName} ${player.lastName}`
      .toLowerCase()
      .includes(query.toLowerCase())
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevDate = () => {
    setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextDate = () => {
    setCurrentDateIndex((prevIndex) =>
      Math.min(prevIndex + 1, dates.length - 1)
    );
  };

  const isLoading = leagueLoading || eventLoading || playerLoading;
  const isError = leagueError || eventError || playerError;

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}

      <div className="w-full">
        {!player && (
          <>
            {league && (
              <>
                <div className="w-full bg-gray-200 rounded mt-5">
                  {!isLoading && !isError && !unscheduled && (
                    <div className="flex justify-center items-center gap-10 p-1">
                      <button
                        onClick={handlePrevDate}
                        disabled={currentDateIndex === 0}
                        className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
                      >
                        <ChevronLeftIcon />
                      </button>
                      <div className="text-gray-700 font-medium">
                        {format(displayedDate, "EEE MMM dd yyyy")}
                      </div>
                      <button
                        onClick={handleNextDate}
                        disabled={currentDateIndex === dates.length - 1}
                        className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
                      >
                        <ChevronRightIcon />
                      </button>
                    </div>
                  )}
                </div>

                <div className="w-full flex  justify-center ">
                  <label
                    htmlFor="scheduleMatches"
                    className=" text-sm font-medium leading-6 text-gray-500 "
                  >
                    <input
                      className="p-1 mr-2 text-gray-500 border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      type="checkbox"
                      checked={unscheduled}
                      onChange={() => setUnscheduled(!unscheduled)}
                    />
                    show unscheduled matches
                  </label>
                </div>
                <div className="w-full flex justify-center items-center mt-4">
                  <label className="mr-2 text-gray-600">
                    Filter by Status:
                  </label>
                  <select
                    className="p-2 border rounded-lg bg-white"
                    value={matchStatus}
                    onChange={(e) => {
                      setMatchStatus(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="completed">Completed</option>
                    <option value="incomplete">Incomplete</option>
                    <option value="challenges">Challenges</option>
                  </select>
                </div>
              </>
            )}
            <div className="w-full flex justify-center">
              <Listbox
                className="w-[350px] border rounded-lg bg-gray-100"
                value={selectedPlayer}
                onChange={setSelectedPlayer}
              >
                <div className="relative mt-10">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="ml-3 block truncate">
                        {selectedPlayer
                          ? `${selectedPlayer?.firstName}, ${selectedPlayer?.lastName}`
                          : "Filter by Player"}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    </span>
                  </Listbox.Button>

                  <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {/* Search Input */}
                    <div className="px-3 py-2">
                      <input
                        type="text"
                        placeholder="Search player..."
                        className="w-full px-3 py-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>

                    {/* Filtered Players */}
                    {filteredPlayers?.length > 0 ? (
                      filteredPlayers.map((player, i) => (
                        <Listbox.Option
                          key={i}
                          value={player}
                          className="group relative cursor-default border-b select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                        >
                          <div className="pl-3 flex">
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                              {player.firstName}
                            </span>
                            ,
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                              {player.lastName}
                            </span>
                          </div>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                          </span>
                        </Listbox.Option>
                      ))
                    ) : (
                      <div className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
                        No players found
                      </div>
                    )}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>
          </>
        )}

        <div className="grid grid-cols-5 w-full">
          <div className="col-span-0 sm:col-span-1"></div>
          <div className="col-span-5 sm:col-span-3">
            {isLoading && (
              <div className="h-[500px] w-full flex justify-center items-center">
                <img className="-mt-32" src={loading} alt="Loading" />
              </div>
            )}
            {isError && (
              <p className="text-center font-medium text-sm text-gray-500">
                No Matches.
              </p>
            )}

            {filteredMatches && (
              <Matches
                matches={filteredMatches}
                admin={admin}
                playerId={playerId}
              />
            )}
            {!selectedPlayer && (
              <div className="flex justify-center items-center gap-3 mt-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="w-8 h-8 p-2 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  <ChevronLeftIcon />
                </button>
                <span className="text-sm font-medium text-gray-600">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="w-8 h-8 p-2 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  <ChevronRightIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeagueMatchesTab;
