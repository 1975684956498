import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const initialState = {
  challenges: [],
  challengeMatches: [],
};

export const groupSlice = createSlice({
  name: "challenge",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getChallengesSend.matchFulfilled,
      (state, action) => {
        state.challenges = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getChallengesReceived.matchFulfilled,
      (state, action) => {
        state.challenges = action.payload.data;
      }
    );
  },
});

export default groupSlice.reducer;
