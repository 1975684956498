import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useEntryNoteMutation,
  useRatingMutation,
  useGetPlayersByLeagueDrawIdQuery,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";
import { eachDayOfInterval, parseISO } from "date-fns";

const getDatesInRange = (startDate, endDate) => {
  if (!startDate || !endDate) return []; // Return an empty array if either date is missing

  return eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
};

const LeagueDrawPlayers = () => {
  const { leagueId } = useParams();
  let leagueDrawId = localStorage.getItem("leagueDrawId");
  let id = JSON.parse(leagueDrawId);

  const { isFetching, refetch } = useGetPlayersByLeagueDrawIdQuery(id);
  const { league } = useSelector((state) => state.leagues);
  const { leagueDrawPlayers } = useSelector((state) => state.leagueDraws);

  const [notes, setNotes] = useState({}); // State to store notes for each entry
  const [rating, setRating] = useState({});
  const [debouncedNotes, setDebouncedNotes] = useState({});
  const [debouncedRating, setDebouncedRating] = useState({});
  const [entryNote] = useEntryNoteMutation();
  const [entryRating] = useRatingMutation();
  const dates = getDatesInRange(league?.startDate, league?.endDate);

  const handleUpdateNote = async (id) => {
    try {
      const res = await entryNote({
        id,
        data: { noteText: debouncedNotes[id] },
      });
      if (res?.data?.error === false) {
        toast.success("Note updated!");
      } else if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const handleRating = async (id) => {
    try {
      const res = await entryRating({
        id,
        data: { rating: debouncedRating[id] },
      });
      if (res?.data?.error === false) {
        toast.success("Rating updated!");
      } else if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const timeouts = Object.keys(notes).reduce((acc, id) => {
      acc[id] = setTimeout(() => {
        setDebouncedNotes((prev) => ({
          ...prev,
          [id]: notes[id],
        }));
      }, 3000);
      return acc;
    }, {});

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [notes]);

  useEffect(() => {
    const timeouts = Object.keys(rating).reduce((acc, id) => {
      acc[id] = setTimeout(() => {
        setDebouncedRating((prev) => ({
          ...prev,
          [id]: rating[id],
        }));
      }, 3000);
      return acc;
    }, {});

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [rating]);

  useEffect(() => {
    refetch();
  }, [leagueDrawId]);

  // Trigger API call when debouncedNotes for an entry changes
  useEffect(() => {
    Object.keys(debouncedNotes).forEach((id) => {
      if (debouncedNotes[id]?.trim() !== "") {
        handleUpdateNote(id);
      }
    });
  }, [debouncedNotes]);

  useEffect(() => {
    Object.keys(debouncedRating).forEach((id) => {
      if (debouncedRating[id]?.trim() !== "") {
        handleRating(id);
      }
    });
  }, [debouncedRating]);

  return (
    <>
      {leagueDrawPlayers?.length > 0 ? (
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {leagueDrawPlayers?.length} players found
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-2 py-3 w-[4%]">
                  Player Name
                </th>
                <th scope="col" className="px-3 py-3 w-[7%]">
                  Draw Rating
                </th>
              </tr>
            </thead>
            <tbody>
              {leagueDrawPlayers?.map((player, i) => (
                <tr
                  key={i}
                  className="odd:bg-gray-50  even:bg-gray-100 border-b"
                >
                  <td>
                    {player?.player?.firstName} {player?.player?.lastName}
                  </td>
                  <td>{player?.rating}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <div className="text-center mt-20 text-gray-500">
            No Draw Player Created Yet!
          </div>
        </div>
      )}
    </>
  );
};

export default LeagueDrawPlayers;
