import React, { useState, useEffect } from "react";
import {
  useGetChallengesReceivedQuery,
  useGetChallengesSendQuery,
  useUpdateChallengeMutation,
} from "../../../redux/features/api/apiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Challenges = ({ league }) => {
  const { currentUser } = useSelector((state) => state.user);
  const id = currentUser?.player?.id;
  let { leagueId } = useParams();

  const {
    data: sentChallenges,
    isLoading,
    refetch,
  } = useGetChallengesSendQuery(id);
  const {
    data: receivedChallenges,
    isLoading: isLoadingReceived,
    refetch: refetchReceived,
  } = useGetChallengesReceivedQuery(id);

  const sentChallengesList = sentChallenges?.data || [];
  const receivedChallengesList = receivedChallenges?.data || [];
  console.log(receivedChallengesList);
  console.log(sentChallengesList);
  const [activeTab, setActiveTab] = useState("sent");

  useEffect(() => {
    if (league) {
      refetch();
      refetchReceived();
    }
  }, [leagueId]);

  const [updateDateChallenge] = useUpdateChallengeMutation();

  const handleChallengeUpdate = (status, challengeId) => {
    updateDateChallenge({ id: challengeId, ChallengeStatus: status })
      .then((response) => {
        if (response?.data?.error === false) {
          toast.success("Challenge Accepted successfully!");
          refetch();
          refetchReceived();
        }
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data.error);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleChallengeRejected = (status, challengeId) => {
    updateDateChallenge({ id: challengeId, ChallengeStatus: status })
      .then((response) => {
        if (response?.data?.error === false) {
          toast.success("Challenge Rejected successfully!");
          refetch();
          refetchReceived();
        }
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data.error);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div className="relative overflow-x-auto">
      {/* Tabs */}
      <div className="flex border-b">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "sent"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("sent")}
        >
          Sent Challenges
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "received"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("received")}
        >
          Received Challenges
        </button>
      </div>

      {/* Loading State */}
      {isLoading || isLoadingReceived ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* Sent Challenges Table */}
          {activeTab === "sent" && (
            <table className="w-full text-sm text-left text-gray-500 shadow-md sm:rounded-lg mt-4">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Player Sender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Player Receiver
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {sentChallengesList.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="px-6 py-3 text-center">
                      No Sent Challenges
                    </td>
                  </tr>
                ) : (
                  sentChallengesList.map((challenge, i) => (
                    <tr
                      key={i}
                      className="odd:bg-gray-50 even:bg-gray-100 border-b"
                    >
                      <td className="px-6 py-1">
                        {challenge.players[0].firstName}{" "}
                        {challenge.players[0].lastName}
                      </td>
                      <td className="px-6 py-1">
                        {challenge.players[1].firstName}{" "}
                        {challenge.players[1].lastName}
                      </td>
                      <td className="px-6 py-1">{challenge.ChallengeStatus}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}

          {/* Received Challenges Table */}
          {activeTab === "received" && (
            <table className="w-full text-sm text-left text-gray-500 shadow-md sm:rounded-lg mt-4">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Player Sender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Player Receiver
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {receivedChallengesList.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="px-6 py-3 text-center">
                      No Received Challenges
                    </td>
                  </tr>
                ) : (
                  receivedChallengesList.map((challenge, i) => (
                    <tr
                      key={i}
                      className="odd:bg-gray-50 even:bg-gray-100 border-b"
                    >
                      <td className="px-6 py-1">
                        {challenge.players[0].firstName}{" "}
                        {challenge.players[0].lastName}
                      </td>
                      <td className="px-6 py-1">
                        {challenge.players[1].firstName}{" "}
                        {challenge.players[1].lastName}
                      </td>
                      <td className="px-6 py-1">{challenge.ChallengeStatus}</td>
                      {challenge.ChallengeStatus === "Pending" && (
                        <td className="px-6 py-1">
                          <button
                            disabled={challenge.ChallengeStatus === "Accepted"}
                            onClick={() =>
                              handleChallengeUpdate("Accepted", challenge.id)
                            }
                            className="text-xs bg-green-300 text-white rounded-sm px-3"
                          >
                            Accept
                          </button>
                          <button
                            disabled={challenge.ChallengeStatus === "Rejected"}
                            onClick={() =>
                              handleChallengeRejected("Rejected", challenge.id)
                            }
                            className="text-xs ml-2 bg-red-300 text-white rounded-sm px-3"
                          >
                            Reject
                          </button>
                        </td>
                      )}
                      {challenge.ChallengeStatus === "Accepted" ? (
                        <td className="px-6 py-1">
                          <button className="text-xs bg-green-300 text-white rounded-sm px-3">
                            Accepted
                          </button>
                        </td>
                      ) : challenge.ChallengeStatus === "Rejected" ? (
                        <td className="px-6 py-1">
                          <button className="text-xs bg-red-300 text-white rounded-sm px-3">
                            Rejected
                          </button>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default Challenges;
